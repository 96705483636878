<template>
    <Reminders hide-top />
</template>

<script>
import Reminders from '@/components/reminders/Reminders';

export default {
    components: {
        Reminders
    }
};
</script>
